import React from 'react'
// import {Outlate} from 'react-router-dom';

function Contact() {
  return (
    <>
    <div>
        <h1>jkbjkbjjkj l</h1>
        <h1>jkbjkbjjkj l</h1>
        <h1>jkbjkbjjkj l</h1>
        <h1>jkbjkbjjkj l</h1>
        <h1>jkbjkbjjkj l</h1>
        <h1>jkbjkbjjkj l</h1>
        Contact</div>
        {/* <Outlate></Outlate> */}
        </>
  )
}

export default Contact