import React from 'react'
import './Service.css'
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CodeIcon from '@mui/icons-material/Code';
import BrushIcon from '@mui/icons-material/Brush';
import MyModale from './Modal';
import { useState } from 'react';

function Service() {
    const [Popup, setPopup] = useState(false)
    const [Popupdata, setPopupdata] = useState('')

    // const Callmodel =()=>{
    //     return (
    //         setPopup(false)
    //     )
        
    // }
    // const MyModale = ()=> {
    //     return(
    //         <div className='Modal_box'>
    //         <div className='popup'>
    //         <h3 className='heading'>I knowe this service</h3>
    //         <button className='popup_btn' onClick={Callmodel}>close</button>
    //         </div>
    //     </div>
    //     )
    // }


    const service_data =[
        {
            heading:'UI/UX',
            logo_name:<DesignServicesIcon/>,
            class_name:'service-box',
            decprition:<div className='decp'>
                <p><h5>1. User Interface (UI):</h5> This refers to the look and feel of a website or app. The UI developer focuses on making the design visually appealing and easy to use. They decide how things should look, including colors, fonts, buttons, icons, and overall layout.</p>
            <p><h5>2.User Experience (UX):</h5> UX is all about how users interact with the digital product. The UX developer's main goal is to make the experience enjoyable and user-friendly. They think about the flow of the app or website, making sure users can navigate it easily and achieve their goals without confusion or frustration.
            </p>
            
<p>
<h5>3.User Research:</h5> Part of the UI/UX developer's job is to understand the target audience. They conduct user research to learn about people's preferences, needs, and pain points. This information helps them design a product that suits the users' requirements.
            </p>
            </div>
            ,
            
        },
        {
            heading:'Front-end Development',
            logo_name: <CodeIcon/>,
            class_name:'service-box',
            decprition:<div className='decp'><p>
            <h5>1.Front-end Development:</h5> UI/UX developers often have front-end development skills. This means they can take the designs they've created and turn them into actual working web pages or app interfaces using programming languages like HTML, CSS, and JavaScript.</p>
            <p>
            <h5>2.Languages: Front-end developers use three main languages:</h5>

            <h5>HTML (Hypertext Markup Language):</h5> It defines the structure of the content, like headings, paragraphs, images, and links.
            <h5>CSS (Cascading Style Sheets):</h5> This language controls the presentation and layout, determining how the content looks on the page, like colors, fonts, and spacing.
            <h5>JavaScript:</h5> It adds interactivity and functionality to the UI, enabling dynamic elements like animations, pop-ups, and form validation.
            </p>
            </div>,
            
        },
        {
            heading:'Branding',
            logo_name:<BrushIcon/>,
            class_name:'service-box',
            decprition:<div className='decp'>
                <h5>1.Identity and Logo:</h5>
<p>A brand's identity is like its fingerprint – it's what makes it distinct and recognizable. The logo is a central visual element that represents the brand's identity, often featuring a unique combination of colors, shapes, and symbols.</p>

<h5>2.Consistency:</h5>
<p>Branding is all about consistency. The brand's identity, messaging, and visual elements should be applied consistently across all communication channels, including websites, social media, packaging, advertisements, and more.</p>

<h5>3.Emotions and Perception:</h5>
<p>Effective branding evokes emotions and shapes how people perceive a brand. For example, a brand might aim to be seen as trustworthy, innovative, or fun, and everything they do, from marketing campaigns to customer service, reinforces that perception.</p>

<h5>4.Brand Promise:</h5>
<p>A brand often has a promise to its customers. It's a commitment to delivering specific benefits, quality, or experiences. Building trust through fulfilling this promise is essential for brand loyalty.</p>

<h5>5.Differentiation:</h5>
<p>In a crowded market, branding helps a company stand out from its competitors. It communicates what makes the brand unique and why customers should choose it over other options.</p>

<h5>6.Brand Experience:</h5>
<p>Branding extends beyond logos and marketing materials. It encompasses the entire customer experience – from the first interaction with the brand to the purchase and post-purchase support.</p>

<h5>7.Brand Advocacy:</h5>
<p>A strong brand can create loyal customers who become advocates, promoting the brand to others through word-of-mouth and social media.</p>

<h5>8.Long-Term Investment:</h5>
<p>Building a brand takes time and consistent effort. It's a long-term investment in establishing recognition, loyalty, and value in the marketplace.</p>

<h5>9.Rebranding:</h5>
<p>Sometimes, companies choose to change or refresh their brand identity through a process called rebranding. This can be done to adapt to market changes, reach new audiences, or reposition the brand.</p>

            </div>,
            
        },
    ]


  return (
    <section>
        {/* <Modale></Modale> */}
        <h1 className='service_heading'>Services</h1>
        <div className='service_three_box'>

            {service_data.map((sarvice_dataPass,index)=>{
                return(
                    <div key={index} className={sarvice_dataPass.class_name} onClick={()=>setPopup(true,setPopupdata(sarvice_dataPass.decprition))}>

                    <div className='service-data-wrapper'>
                    <div className='icon_text'>
                        <div className='icon_text_iconbox'>
                            <span className='service_ico'>{sarvice_dataPass.logo_name}</span>
                        </div>
                        <div className='icon_text_textbox'>
                            <p className='para'>{sarvice_dataPass.heading}</p>
                        </div>
                    </div>
            
                    <div className='modalButton'>
                        <p >Viwe more <span className='btn_arrow'><ArrowRightAltIcon/></span></p>
                    </div>
                    </div>
                    
            
                    
                    </div>

                    
                )
            })
            
            }
           

      



        {/* second box


        <div className='service-box'>

        <div className='service-data-wrapper'>
        <div className='icon_text'>
            <div className='icon_text_iconbox'>
                <span className='service_ico'><CodeIcon/></span>
            </div>
            <div className='icon_text_textbox'>
                <p className='para'>Front-end<br/> Development</p>
            </div>
        </div>

        <div className='modalButton'>
            <p onClick={()=>setPopup(true)}>Viwe more <span className='btn_arrow'><ArrowRightAltIcon/></span></p>
            
        </div>
        </div>

        
        </div> */}

        {/* third box */}


        {/* <div className='service-box'>

        <div className='service-data-wrapper'>
        <div className='icon_text'>
            <div className='icon_text_iconbox'>
                <span className='service_ico'><BrushIcon/></span>
            </div>
            <div className='icon_text_textbox'>
                <p className='para'>Branding </p>
            </div>
        </div>

        <div className='modalButton'>
            <p onClick={()=>setPopup(true)}>Viwe more <span className='btn_arrow'><ArrowRightAltIcon/></span></p>
        </div>
        </div>

        
        </div> */}

    </div>
    {/* {Popup && <MyModale Callmodel={Callmodel} />} */}
    {Popup && 
           
                <div className='Modal_box'>
        <div className='popup'>
           
            {Popupdata}
           
        {/* <h3 className='heading'></h3> */}
        <button className='popup_btn' onClick={()=>setPopup(false)}>close</button>
        </div>
    </div>
        

 
    }
    </section>
  )
}

export default Service