import React from 'react'

function Dynamicproject() {
  return (
    <div>Dynamicproject
        <p>dsjfhjsd Lorem ipsum dolor, sit amet consectetur adipisicing elit. Neque animi natus autem architecto nemo, quibusdam excepturi hic? Dolores, provident! Perferendis nobis autem eum corporis vel rerum ducimus porro optio sit.</p>
    </div>
  )
}

export default Dynamicproject