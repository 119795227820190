import React from 'react'
import './FooterStyle.css'



function Footer() {
  const socialconnect =[
    {
      name: "🐦 Twitter",
      link:"dfdsfds",
      icon:'',
      

    },
    {
      name: "💻 LinkedIn",
      link:"dfdsfds",
      icon:'',


    },
    {
      name: "📸 Instagram",
      link:"dfdsfds",
      icon:'',


    },
  ]
  return (
    <div className='footer_wrapper'>

    
    <div className='footer_box'>
     
        {
          socialconnect.map((buttonsocial,index)=>{
            return(
              <button className='button_social'>
              <a className='social_ancr' href={buttonsocial.link}>
                <i>{buttonsocial.icon}</i>
                <span className="text">{buttonsocial.name}</span>

              </a>
              </button>
            )
          })
        }
        
      

    </div>

    <div className="ocean">
       <div className='copy_right'>
      © 2021 All rights reserved | self made
    </div>
    
  <div className="wave"></div>
  <div className="wave"></div>
</div>
   
    {/* <div>
      © 2021 All rights reserved | self made
    </div> */}
    </div>
  )
}

export default Footer